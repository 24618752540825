.single_contact_info_7sft {
  background: #ffffff;
  box-shadow: 0px 0px 100px 0px rgba(0, 0, 0, 0.2);
  padding: 55px;
  border-radius: 10px;
  margin-top: 70px;
  text-align: center;
  height: 250px;
  .info_icon_7sft {
    width: 100px;
    height: 100px;
    line-height: 103px;
    background: #005de0;
    color: #ffffff;
    border-radius: 50%;
    margin: 0 auto;
    margin-top: -115px;
    font-size: 40px;
  }
  .info_contact_7sft {
    .title {
      font-size: 18px;
      line-height: 36px;
      color: #333333;
      margin-top: 15px;
      font-weight: 800;
    }
    p {
      font-size: 15px;
      line-height: 36px;
      font-weight: 700;
      color: #005de0;
      -webkit-text-decoration-color: #7faeef;
      text-decoration-color: #7faeef;
    }
  }
}
.single_contact_info_7sft_location{
  background: #ffffff;
  box-shadow: 0px 0px 100px 0px rgba(0, 0, 0, 0.2);
  padding-top: 55px;
  border-radius: 10px;
  margin-top: 70px;
  text-align: center;
  height: 250px;
  .info_icon_7sft {
    width: 100px;
    height: 100px;
    line-height: 103px;
    background: #005de0;
    color: #ffffff;
    border-radius: 50%;
    margin: 0 auto;
    margin-top: -115px;
    font-size: 40px;
  }
  .info_contact_7sft {
    .title {
      font-size: 18px;
      line-height: 36px;
      color: #333333;
      margin-top: 15px;
      font-weight: 800;
    }
    p {
      font-size: 15px;
      line-height: 36px;
      font-weight: 700;
      color: #005de0;
      -webkit-text-decoration-color: #7faeef;
      text-decoration-color: #7faeef;
    }
  }

}

.contact_form_section {
  .contact_wrap_7sft {
    .form_title_7sft {
      text-align: center;
      .title {
        font-size: 36px;
        line-height: 48px;
        font-weight: 600;
        color: #333333;
      }
    }

    .contact_form_wrap_7sft {
      .single_input_7sft {
        margin-top: 10px;
        input {
          border: 1px solid transparent;
          background: #f6f7f9;
          border-radius: 0;
        }

        textarea {
          border: 1px solid transparent;
          background: #f6f7f9;
          border-radius: 0;
          resize: none;
        }
      }
    }

  }
}

.map_wrap_section_7sft {
  .map_wrap_7sft {
    .form_title_7sft {
      text-align: center;
      .title {
        font-size: 36px;
        line-height: 48px;
        font-weight: 600;
        color: #333333;
      }
    }
  }

  iframe {
    height: 550px;
    width: 100%;
    filter: brightness(100%) contrast(100%) saturate(0%) blur(0px)
      hue-rotate(0deg);
  }
}

#rc-anchor-alert, .rc-anchor-alert {
    visibility: hidden !important;
}



