.syb_list{
    list-style-type: square;
    li{
        margin-left: 50px;
    }
}

.second_margin{
    margin-top: 12px;
}

.navlnk{
    text-decoration: none;
}

.service_margin{
    margin-top: 200px;
}