.eventCard_7sft {
  background: #ffffff;
  box-shadow: 0px 0px 100px 0px rgba(0, 0, 0, 0.2);
  border-radius: 10px;
  margin-top: 70px;
  text-align: center;
  .single-testimonial{
      padding-top: 0px;
      margin: 12px 10px !important;
      
  }
  .testimonial-content{
      margin-bottom: 10px;
  }
   .event_card_height {
    height: 300px;
    img{
        height: 300px;
        width: 100%;
		object-fit: contain;
    }
	 video{
	 height: 300px ;
    width: 100% ;
	object-fit: contain;
	}
  }
  .slick-prev:before,
  .slick-next:before {
    color: #000 !important;
  }
  .event_content {
    border-top: 1px solid #ebebeb;
    padding: 15px 40px;

    .blog_meta_7sft {
      span {
        font-size: 14px;
        line-height: 24px;
        font-weight: 600;
        color: #999999;
        margin-right: 15px;
        i {
          color: #005de0;
          margin-right: 5px;
        }
      }
    }
    .title_7sft {
      font-size: 22px;
      line-height: 30px;
      color: #333333;
      padding-right: 15px;
      margin-top: 15px;
      font-weight: 800 !important;
      transition: all 0.3s linear;
      &:hover {
        color: #005de0;
      }
    }
    p {
      font-size: 14px;
      line-height: 26px;
      font-weight: 400;
      color: #4c4d56;
      margin-top: 10px;
      text-overflow: ellipsis;
      line-clamp: 2;
      white-space: nowrap;
      overflow: hidden;
    }
  }
}

.remove-button{
  position:absolute;
  top: 23px;
  right: -12px;
  color: red;
  background-color: white;
  cursor: pointer;
}

.image_conatiner{
  width: 150px;
  height: 150px;
  position: relative;
  margin-top: 10px;
  margin-right: 8px;

  img{
    width: 150px !important;
    height: 150px !important; 
  }

  video{
    width: 150px !important;
    height: 150px !important;
  }
}

.image_veiw{
  height: 200px !important;
  overflow: auto;
}
