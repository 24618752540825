@media only screen and (max-width: 575px) {

  .slick-dots{
    bottom: 9px !important;
  }

  .sect_margin{
    margin-bottom: 12% !important;
  }

  .single-testimonial {
    padding: 0px !important;
  }

  .counter_text_7sft {
    span {
      font-size: 25px !important;
    }
  }

  .counter_wrap_7sft {
    .counter_icons_7sft {
      margin-bottom: 10px !important;

      img {
        width: 45px !important;
      }
    }
  }

  .case-study-btn{ 
    .btn_7sft {
    text-decoration: none !important;
    display: inline-block !important;
    position: relative !important;
    overflow: hidden !important;
    z-index: 1 !important;
    background-color: #005de0 !important;
    border-color: transparent !important;
    color: #ffffff !important;
    border-radius: 5px !important;
    font-size: 13px !important;
    font-weight: 700 !important;
    height: 40px !important;
    line-height: 37px !important;
    padding: 0px 14px !important;
    border: 2px solid #005de0 !important;
    border-style: solid !important;
    box-shadow: none !important;
    transition: all 0.3s ease 0s !important;
    cursor: pointer !important;
    z-index: 1 !important;
    position: relative !important;
  }


  .btn_7sft_disabled{
    background-color: #6c98d5 !important;
    text-decoration: none !important;
    display: inline-block !important;
    position: relative !important;
    overflow: hidden !important;
    z-index: 1 !important;
    border-color: transparent !important;
    color: #ffffff !important;
    border-radius: 5px !important;
    font-size: 13px !important;
    font-weight: 700 !important;
    height: 40px !important;
    line-height: 37px !important;
    padding: 0px 14px !important;
    border: 2px solid #6c98d5 !important;
    border-style: solid !important;
    box-shadow: none !important;
    transition: all 0.3s ease 0s !important;
    cursor: pointer !important;
    z-index: 1 !important;
    position: relative !important;
    
  }

}

  h3,
  .title_7sft,
  .title {
    font-size: 18px !important;
    line-height: 25px !important;
  }

  .page-banner {
    h2 {
      font-size: 33px !important;
    }
  }


  .main_tab {
    .tab_nav_7sft {
      min-height: 100% !important;
    }
  }

  .App-header {
    .Widget_widget__Card {
      width: 100% !important;
    }
  }

  .log_width_container {
    height: 100% !important;
  }

  .foot_text {
    text-align: center;
  }

  .single_contact_info_7sft {
    .info_contact_7sft {
      p {
        font-size: 12px;
      }
    }
  }


  .foot_icons {
    align-items: center !important;
    display: flex !important;
    justify-content: center !important;
    margin-top: 10px !important;
  }

  .our_team_backImage {
    width: 100% !important;
    height: 100% !important;
    background-size: cover !important;
  }

  .mobile_menu {
    display: block !important;
  }

  body {
    overflow-x: hidden !important;
  }

  .bm-burger-button {
    right: 20px !important;
  }

  .desktop_menu {
    display: none !important;
  }

  .banner {
    height: 550px !important;

    .video_class {
      border-bottom-left-radius: 0% !important;
      border-bottom-right-radius: 0% !important;

    }

    .shape-1 {
      width: 180px !important;
      height: 180px !important;
      left: 17px !important;
      top: 23% !important;
    }

    h5 {
      font-size: 14px !important;
      line-height: 26px !important;
    }

    h3 {
      font-size: 18px !important;
      line-height: 25px !important;
    }
  }

  .counter_section_7sft {
    height: 100% !important;
  }

  .about_content_7sft {
    .section_title_7sft {
      .title_7sft {
        font-size: 18px !important;
        line-height: 25px !important;
      }
    }
  }

  .features_section_7sft {
    .shap1_7sft {
      img {
        width: 100% !important;
      }
    }

    .features_content_wrap_7sft {
      .section_title_7sft {
        padding: 0px !important;

        .title_7sft {
          font-size: 18px !important;
          line-height: 25px !important;
        }
      }
    }
  }

  .features_item_7sft {
    width: 100% !important;
  }

  .page-banner-section {
    .shape-banner2 {
      width: 300px !important;
      height: 300px !important;
      left: -28% !important;
      bottom: -21% !important;
    }
  }
}

@media only screen and (max-width: 1199px) {

  .slick-dots{
    bottom: 9px !important;
  }

  .sect_margin{
    margin-bottom: 12% !important;
  }

  .single-testimonial {
    padding: 0px !important;
  }

  .counter_text_7sft {
    span {
      font-size: 25px !important;
    }
  }

  .counter_wrap_7sft {
    .counter_icons_7sft {
      margin-bottom: 10px !important;

      img {
        width: 45px !important;
      }
    }
  }


  h3,
  .title_7sft,
  .title {
    font-size: 18px !important;
    line-height: 25px !important;
  }

  .case-study-btn .btn_7sft {
    text-decoration: none !important;
    display: inline-block !important;
    position: relative !important;
    overflow: hidden !important;
    z-index: 1 !important;
    background-color: #005de0 !important;
    border-color: transparent !important;
    color: #ffffff !important;
    border-radius: 5px !important;
    font-size: 13px !important;
    font-weight: 700 !important;
    height: 40px !important;
    line-height: 37px !important;
    padding: 0px 14px !important;
    border: 2px solid #005de0 !important;
    border-style: solid !important;
    box-shadow: none !important;
    transition: all 0.3s ease 0s !important;
    cursor: pointer !important;
    z-index: 1 !important;
    position: relative !important;
  }

  .main_tab {
    .tab_nav_7sft {
      min-height: 100% !important;
    }
  }

  .log_width_container {
    height: 100% !important;
  }

  .App-header {
    .Widget_widget__Card {
      width: 100% !important;
    }
  }

  .single_contact_info_7sft {
    .info_contact_7sft {
      p {
        font-size: 12px;
      }
    }
  }

  .foot_text {
    align-items: center;
    display: flex;
    justify-content: center;
    text-align: center;
  }

  .our_team_backImage {
    width: 100% !important;
    height: 100% !important;
    background-size: cover !important;
  }

  .foot_icons {
    align-items: center !important;
    display: flex !important;
    justify-content: center !important;
    margin-top: 10px !important;
  }

  .mobile_menu {
    display: block !important;
  }

  body {
    overflow-x: hidden !important;
  }

  .bm-burger-button {
    right: 20px !important;
  }

  .desktop_menu {
    display: none !important;
  }

  .banner {
    height: 550px !important;

    .video_class {
      border-bottom-left-radius: 0% !important;
      border-bottom-right-radius: 0% !important;

    }

    .shape-1 {
      width: 180px !important;
      height: 180px !important;
      left: 17px !important;
      top: 23% !important;
    }

    h5 {
      font-size: 14px !important;
      line-height: 26px !important;
    }

    h3 {
      font-size: 18px !important;
      line-height: 25px !important;
    }
  }

  .counter_section_7sft {
    height: 100% !important;
  }

  .about_content_7sft {
    .section_title_7sft {
      .title_7sft {
        font-size: 18px !important;
        line-height: 25px !important;
      }
    }
  }

  .features_section_7sft {
    .shap1_7sft {
      img {
        width: 100% !important;
      }
    }

    .features_content_wrap_7sft {
      .section_title_7sft {
        padding: 0px !important;

        .title_7sft {
          font-size: 18px !important;
          line-height: 25px !important;
        }
      }
    }
  }

  .page-banner-section {
    .shape-banner2 {
      width: 300px !important;
      height: 300px !important;
      left: -28% !important;
      bottom: -21% !important;
    }
  }
}

@media only screen and (max-width: 767px) {

  .slick-dots{
    bottom: 9px !important;
  }


  .sect_margin{
    margin-bottom: 12% !important;
  }

  .single-testimonial {
    padding: 0px !important;
  }

  .counter_text_7sft {
    span {
      font-size: 25px !important;
    }
  }

  .counter_wrap_7sft {
    .counter_icons_7sft {
      margin-bottom: 10px !important;

      img {
        width: 45px !important;
      }
    }
  }


  h3,
  .title_7sft,
  .title {
    font-size: 18px !important;
    line-height: 25px !important;
  }

  .case-study-btn .btn_7sft {
    text-decoration: none !important;
    display: inline-block !important;
    position: relative !important;
    overflow: hidden !important;
    z-index: 1 !important;
    background-color: #005de0 !important;
    border-color: transparent !important;
    color: #ffffff !important;
    border-radius: 5px !important;
    font-size: 13px !important;
    font-weight: 700 !important;
    height: 40px !important;
    line-height: 37px !important;
    padding: 0px 14px !important;
    border: 2px solid #005de0 !important;
    border-style: solid !important;
    box-shadow: none !important;
    transition: all 0.3s ease 0s !important;
    cursor: pointer !important;
    z-index: 1 !important;
    position: relative !important;
  }

  .main_tab {
    .tab_nav_7sft {
      min-height: 100% !important;
    }
  }

  .log_width_container {
    height: 100% !important;
  }


  .App-header {
    .Widget_widget__Card {
      width: 100% !important;
    }
  }

  .single_contact_info_7sft {
    .info_contact_7sft {
      p {
        font-size: 12px;
      }
    }
  }

  .foot_text {
    align-items: center;
    display: flex;
    justify-content: center;
    text-align: center;
  }

  .our_team_backImage {
    width: 100% !important;
    height: 100% !important;
    background-size: cover !important;
  }

  .foot_icons {
    align-items: center !important;
    display: flex !important;
    justify-content: center !important;
    margin-top: 10px !important;
  }

  .mobile_menu {
    display: block !important;
  }

  .bm-burger-button {
    right: 20px !important;
  }

  body {
    overflow-x: hidden !important;
  }

  .desktop_menu {
    display: none !important;
  }

  .banner {
    height: 550px !important;

    .video_class {
      border-bottom-left-radius: 0% !important;
      border-bottom-right-radius: 0% !important;

    }

    .shape-1 {
      width: 180px !important;
      height: 180px !important;
      left: 17px !important;
      top: 23% !important;
    }

    h5 {
      font-size: 14px !important;
      line-height: 26px !important;
    }

    h3 {
      font-size: 18px !important;
      line-height: 25px !important;
    }
  }

  .counter_section_7sft {
    height: 100% !important;
  }

  .about_content_7sft {
    .section_title_7sft {
      .title_7sft {
        font-size: 18px !important;
        line-height: 25px !important;
      }
    }
  }

  .features_section_7sft {
    .shap1_7sft {
      img {
        width: 100% !important;
      }
    }

    .features_content_wrap_7sft {
      .section_title_7sft {
        padding: 0px !important;

        .title_7sft {
          font-size: 18px !important;
          line-height: 25px !important;
        }
      }
    }
  }

  .page-banner-section {
    .shape-banner2 {
      width: 300px !important;
      height: 300px !important;
      left: -28% !important;
      bottom: -21% !important;
    }
  }
}

@media only screen and (min-width: 768px) and (max-width: 1366px) {

  .slick-dots{
    bottom: 9px !important;
  }

  .sect_margin{
    margin-bottom: 12% !important;
  }

  .single-testimonial {
    padding: 0px !important;
  }

  .counter_text_7sft {
    span {
      font-size: 25px !important;
    }
  }

  .counter_wrap_7sft {
    .counter_icons_7sft {
      margin-bottom: 10px !important;

      img {
        width: 45px !important;
      }
    }
  }


  h3,
  h2,
  .title_7sft,
  .title {
    font-size: 18px !important;
    line-height: 25px !important;
  }

  .case-study-btn .btn_7sft {
    text-decoration: none !important;
    display: inline-block !important;
    position: relative !important;
    overflow: hidden !important;
    z-index: 1 !important;
    background-color: #005de0 !important;
    border-color: transparent !important;
    color: #ffffff !important;
    border-radius: 5px !important;
    font-size: 13px !important;
    font-weight: 700 !important;
    height: 40px !important;
    line-height: 37px !important;
    padding: 0px 14px !important;
    border: 2px solid #005de0 !important;
    border-style: solid !important;
    box-shadow: none !important;
    transition: all 0.3s ease 0s !important;
    cursor: pointer !important;
    z-index: 1 !important;
    position: relative !important;
  }


  .main_tab {
    .tab_nav_7sft {
      min-height: 100% !important;
    }
  }

  .log_width_container {
    height: 100% !important;
  }

  .App-header {
    .Widget_widget__Card {
      width: 100% !important;
    }
  }

  .single_contact_info_7sft {
    .info_contact_7sft {
      p {
        font-size: 12px;
      }
    }
  }

  .our_team_backImage {
    height: 590px !important;
  }

  .service_section {
    .service_wraper {
      .service-item {
        height: 529px !important;
      }
    }
  }

  .features_section_7sft {
    .shap1_7sft {
      left: 61px !important;

      img {
        width: 500px !important;
      }
    }
  }
}