.ul_list{
    list-style-type: square;
    li{
        margin-left: 50px;
    }

}

.top_margin_sec{
    margin-top: 12px;
}

.benif{
    li{
        list-style: none;
        list-style-type: none;
        i{
            margin-right: 10px;
            font-size: 22px;
        }

        margin-left: 0px;
    }
    p{
        margin-top: 0px !important;
    }
}

.about_content_7sft {
    p{
    margin-top: 0px !important;
    }

    
    h2{
        margin-bottom: 0px !important;
        margin-top: 20px !important;
    }
}
