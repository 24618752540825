.job_section_7sft {
  .single_job_wrapper {
    background: #ffffff;
    box-shadow: 0px 0px 100px 0px rgba(0, 0, 0, 0.2);
    padding: 25px;
    border-radius: 10px;
    margin-top: 40px;
  }

  .job_name_wrapper {
    h5 {
      font-weight: 800;
      margin: 0;
      color: #005de0;
      transition: 0.5s;
      &:hover {
        cursor: pointer;
      }
    }
    p {
      margin: 2px 0 0 0;
      font-size: 0.9rem;
      font-weight: 800;
    }
  }

  .job_description_wrapper {
    h6 {
      font-weight: 800;
      margin: 0;
      transition: 0.5s;
      color: #005de0;
    }
    p {
      text-overflow: ellipsis;
      line-clamp: 2;
      white-space: nowrap;
      overflow: hidden;
      margin: 2px 0 0 0;
      font-size: 0.7rem;
      font-weight: 800;
    }
  }
}

.job_description_wrapper_7sft {
  .case-study-btn {
    margin-top: 5px;
    .btn_7sft {
      text-decoration: none;
      display: inline-block;
      position: relative;
      overflow: hidden;
      z-index: 1;
      background-color: #005de0;
      border-color: transparent;
      color: #ffffff !important;
      border-radius: 5px;
      font-size: 14px;
      font-weight: 600;
      height: 40px;
      line-height: 36px;
      padding: 0px 55px;
      border: 2px solid #005de0;
      border-style: solid;
      box-shadow: none;
      transition: all 0.3s ease 0s;
      cursor: pointer;
      z-index: 1;
      position: relative;
      a {
        &:hover {
          text-decoration: none;
          color: #005de0 !important;
        }
      }
      &:hover {
        box-shadow: none;
        color: #005de0 !important;
        &::after {
          transform: skewX(45deg) scale(1, 1);
        }
      }
      &:after {
        position: absolute;
        content: "";
        z-index: -1;
        display: block;
        left: -20%;
        right: -20%;
        top: -4%;
        height: 150%;
        width: 150%;
        bottom: 0;
        background: #ffffff;
        transform: skewX(45deg) scale(0, 1);
        transition: all 0.5s ease 0s;
      }
    }
  }


  .btn_7sft_disabled{
    text-decoration: none;
    display: inline-block;
    position: relative;
    overflow: hidden;
    z-index: 1;
    background-color: #6c98d5;
    border-color: transparent;
    color: #ffffff !important;
    border-radius: 5px;
    font-size: 14px;
    font-weight: 600;
    height: 40px;
    line-height: 36px;
    padding: 0px 55px;
    border: 2px solid #6c98d5;
    border-style: solid;
    box-shadow: none;
    transition: all 0.3s ease 0s;
    cursor: pointer;
    z-index: 1;
    position: relative;
  }
}

.main_head_vac {
  .form_title_7sft {
    text-align: center;
    .title {
      font-size: 36px;
      line-height: 48px;
      font-weight: 600;
      color: #333333;
    }
  }
}

.list_content_sub{
  padding-left: 2rem !important;
  li{
    list-style: square !important;
    list-style-type: square;
    h6{
      font-size: 12px !important;
    }
  }
}

.sub_title_sub {
  font-size: 18px;
  line-height: 30px;
  font-weight: 800;
  color: #000000;
  letter-spacing: 1px;
  text-transform: uppercase;
  margin-bottom: 12px;
}

.status{
  font-weight: bold;
  }
  
  .status_ative{
    color: green;
    font-weight: bold;
  }
  
  .status_inactive{
    color: red;
    font-weight: bold;
  }
  
  .image_wraper{
  width: 100%;
  height: 230px;
  padding: 5px;
  img{
    width: 100%;
    height: 100%;
  }
  }

  .sub_title_career {
    font-size: 18px;
    line-height: 30px;
    font-weight: 800;
    color: #005de0;
    letter-spacing: 1px;
    text-transform: uppercase;
    margin-bottom: 12px;
  }