
* {
  box-sizing: border-box;
}

  .App-header {
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
 // color: rgb(65, 62, 62);
  // background: radial-gradient(farthest-side ellipse at 10% 0, #333867 20%, #17193B);
.Widget_widget__Card{
  background: #ffffff;
  box-shadow: 0px 0px 100px 0px rgba(0, 0, 0, 0.2);
  padding: 55px;
  border-radius: 10px;
  margin-top: 70px;
  text-align: center;
  width: 30%;

  .btn_log_7sft {
    text-decoration: none;
    display: inline-block;
    position: relative;
    overflow: hidden;
    z-index: 1;
    background-color: #005de0;
    border-color: transparent;
    color: #ffffff !important;
    border-radius: 5px;
    font-size: 15px;
    font-weight: 700;
    height: 45px;
    line-height: 20px;
    padding: 0px 20px;
    border: 2px solid #005de0;
    border-style: solid;
    box-shadow: none;
    transition: all 0.3s ease 0s;
    cursor: pointer;
    z-index: 1;
    position: relative;
    &:hover {
        box-shadow: none;
        color: #005de0 !important;
        &::after {
          transform: skewX(45deg) scale(1, 1);
      }
    }
      &:after {
        position: absolute;
        content: "";
        z-index: -1;
        display: block;
        left: -20%;
        right: -20%;
        top: -4%;
        height: 150%;
        width: 150%;
        bottom: 0;
        background: #ffffff;
        transform: skewX(45deg) scale(0, 1);
        transition: all 0.5s ease 0s;
    }
}
}
  .single_log_input_7sft {
    margin-top: 20px;
    input {
      border: 1px solid transparent;
      background: #f6f7f9;
      border-radius: 0;
    }

    textarea {
      border: 1px solid transparent;
      background: #f6f7f9;
      border-radius: 0;
      resize: none;
    }
  }

  .log_width{
    max-width: 500px;
    justify-content: center;
  }


  }