.main_tab {
  .single_input_7sft {
    margin-top: 20px;
    input {
      border: 1px solid transparent;
      background: #f6f7f9;
      border-radius: 0;
    }

    select{
      border: 1px solid transparent;
      background: #f6f7f9;
      border-radius: 0;
    }

    textarea {
      border: 1px solid transparent;
      background: #f6f7f9;
      border-radius: 0;
      resize: none;
    }
  }
  .nav-pills .nav-link.active,
  .nav-pills .show > .nav-link {
    background-color: #f6f7f9 !important;
    color: #005de0 !important;
  }
  .tab_nav_7sft {
    background: #000c29;
    max-height: calc(100vh - 75px);
    min-height: 100vh;
  }
  .tabs_page_7sft {
    max-height: calc(100vh - 75px);
    min-height: 100vh;
    overflow: hidden;
  }
  .nav-link {
    color: white !important;
    font-weight: 600 !important;
    cursor: pointer !important;
  }

  .herder_panel_7sft{
    box-shadow: 0px 0px 16px 0px rgba(0 ,0 ,0, 0.5);
    .panel_heding_7sft{
      padding: 20px 20px;
     h1{
      font-weight: 600 !important;
      font-size: 2rem;
      color: #005de0 !important;
     }
    }
    .panel_heding_icons{
      padding: 20px 30px;
      display: flex;
      align-items: center;
      justify-content: end;
      font-weight: 600 !important;
      font-size: 1.3rem;
      color: #005de0 !important;
      cursor: pointer;
    }
  }

  .btn_log_7sft {
    text-decoration: none;
    display: inline-block;
    position: relative;
    overflow: hidden;
    z-index: 1;
    background-color: #005de0;
    border-color: transparent;
    color: #ffffff !important;
    border-radius: 5px;
    font-size: 15px;
    font-weight: 700;
    height: 45px;
    line-height: 20px;
    padding: 0px 20px;
    border: 2px solid #005de0;
    border-style: solid;
    box-shadow: none;
    transition: all 0.3s ease 0s;
    cursor: pointer;
    z-index: 1;
    position: relative;
    &:hover {
        box-shadow: none;
        color: #005de0 !important;
        &::after {
          transform: skewX(45deg) scale(1, 1);
      }
    }
      &:after {
        position: absolute;
        content: "";
        z-index: -1;
        display: block;
        left: -20%;
        right: -20%;
        top: -4%;
        height: 150%;
        width: 150%;
        bottom: 0;
        background: #ffffff;
        transform: skewX(45deg) scale(0, 1);
        transition: all 0.5s ease 0s;
    }
}

}

.log_width_container{
  max-height: calc(80vh - 60px);
  min-height: 80vh;
overflow: auto;

}


.btn_download{
  color: white !important;
  font-size: 20px;
  font-weight: bold !important;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.closeDisp{
  display: flex;
  margin-top: 3px;
  color: #ce0606;
  font-size: 20px;
}